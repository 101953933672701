<template>
	<div class="animated fadeIn">
		<div
			v-if="!$route.params.distributorId"
			class="row">
			<div class="col-12 col-md-3">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr v-if="!$route.params.distributorId">
		<data-filter
			v-if="!$route.params.distributorId"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="!$route.params.distributorId"
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							v-if="!$route.params.distributorId"
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th class="border-top-0">
							{{ translate('action') }}
						</th>
						<th class="border-top-0">
							{{ translate('changes') }}
						</th>
						<th class="border-top-0">
							{{ translate('notes') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('causer_name')">
							{{ translate('causer') }}
							<sort field="causer_name" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('date_time') }}
							<sort field="created_at" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="!$route.params.distributorId"
							class="align-middle">
							<span
								v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="!$route.params.distributorId"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.description }}
						</td>
						<td class="align-middle">
							<template v-for="(val, key) in JSON.parse(item.attributes.previous_value)">
								<strong
									:key="`${key}_prev`">{{ translate('previous') }}:</strong>
								{{ getFormattedVal(key, val) }},
								<strong
									:key="`${key}_new`">{{ translate('new') }}:</strong>
								{{ getFormattedVal(key, JSON.parse(item.attributes.new_value)[key]) }}
								<template
									v-if="key !== Object.keys(JSON.parse(item.attributes.new_value))[Object.keys(JSON.parse(item.attributes.new_value)).length - 1]">
									|
								</template>
							</template>
							<template v-for="(val, key) in JSON.parse(item.attributes.new_value)">
								<template
									v-if="item.attributes.previous_value === null || typeof JSON.parse(item.attributes.previous_value)[key] === 'undefined'">
									<strong
										:key="`${key}_new`">{{ translate('new') }}:</strong>
									{{ getFormattedVal(key, JSON.parse(item.attributes.new_value)[key]) }}
									<template
										v-if="key !== Object.keys(JSON.parse(item.attributes.new_value))[Object.keys(JSON.parse(item.attributes.new_value)).length - 1]">
										|
									</template>
								</template>
							</template>
						</td>
						<td class="align-middle">
							{{ item.attributes.notes }}
						</td>
						<td class="align-middle">
							{{ item.attributes.causer_fullname }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.created_at.date).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { admin, distributor } from '@/settings/Roles';
import {
	Profile, Report as messages, DistributorsInformation, Ranks, Packages, NIN,
} from '@/translations';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import {
	YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG, MDY_FORMAT,
} from '@/settings/Dates';
import {
	DATE_KEYS as dateKeys, BOOLEAN_KEYS as booleanKeys, TRANSLATED_KEYS as translatedKeys,
} from '@/settings/Profile';
import Report from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'UserNotes',
	messages: [Profile, messages, DistributorsInformation, Ranks, Packages, NIN],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			endpoint,
			dateRanges,
			calendarConfig: V_CALENDAR_CONFIG,
			report: new Report(),
			dateFormat: YMDHMS_FORMAT,
			dateKeys,
			booleanKeys,
			translatedKeys,
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.report.data.loading;
		},
		pagination() {
			return this.report.data.pagination;
		},
		data() {
			try {
				const { data } = this.report.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		if (this.$route.params.distributorId) {
			this.$route.query.id = this.$route.params.distributorId;
		}

		this.report.getUserNotes();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.report.getUserNotes(options);
		},
		getFormattedVal(key, value) {
			let formattedValue = value;
			if (this.dateKeys.includes(key)) {
				let dateValue = this.translate('null_date_label');
				if (formattedValue !== null) {
					dateValue = this.$moment(value).format(MDY_FORMAT);
				}
				formattedValue = dateValue;
			} else if (this.translatedKeys.includes(key)) {
				formattedValue = value ? this.translate(value.toLowerCase()) : value;
			} else if (this.booleanKeys.includes(key)) {
				formattedValue = this.translate(`${!!value}`);
			}

			return formattedValue;
		},
	},
};
</script>
